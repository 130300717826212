'use client';
import * as React from 'react';

import { usePathname } from 'next/navigation';
import dynamic from 'next/dynamic';

const GTM = dynamic(() => import('@ui/GTM/GTM'));
const ZenDeskLoaderButton = dynamic(() => import('@ui/zendesk-loader-btn/zendesk-loader-btn'));
const CookiesPopup = dynamic(() => import('@ui/FSAStore/CookiesPopup'));
const ThemeProvider = dynamic(() => import('@ui/FSAStore/theme/Provider'));
const LivelySettingsProvider = dynamic(() => import('@ui/FSAStore/context/LivelySettings'));
const ThemeRegistry = dynamic(() => import('@ui/ThemeRegistry/ThemeRegistry'));
const JsonLdProvider = dynamic(() => import('@ui/JsonLd'));

import useDrift from '../hooks/useDrift';
// This appears to have a requirements to be a named export
const DRIFT_URL_FILTERS = [
  '/broker',
  '/brokers',
  '/employers',
  '/partners',
  '/fsa',
  '/hra',
  '/cobra',
  '/lsa',
  '/mta',
  '/contact-sales',
  '/resources/employer',
  '/resources/broker'
];
export const AppProvider = ({ children }: { children: any }) => {
  const asPath = usePathname();
  const currentPath = asPath?.split('?')[0]?.split('#')[0] || '';

  const shouldRenderDriftScript = DRIFT_URL_FILTERS.includes(currentPath);
  useDrift();

  return (
    <ThemeRegistry>
      <JsonLdProvider>{children}</JsonLdProvider>

      <LivelySettingsProvider>
        <ThemeProvider>
          <CookiesPopup />
        </ThemeProvider>
      </LivelySettingsProvider>
      {!shouldRenderDriftScript && <ZenDeskLoaderButton />}
      <GTM />
      <link rel="stylesheet" type="text/css" charSet="UTF-8" href="/static/css/slick.css" />
    </ThemeRegistry>
  );
};

export default AppProvider;
