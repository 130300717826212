'use client';
import { usePathname } from 'next/navigation';
import * as React from 'react';

type ScriptTag = HTMLElement | null;
type DriftHook = () => void;
type DriftApi = {
  widget: {
    show: () => void;
    hide: () => void;
  };
};
type DriftConversationEventData = {
  buttonBody: string;
};

const DRIFT_STATUS_IS_READY: string = 'ready';
const DRIFT_CONVERSATION_EVENT: string = 'conversation:buttonClicked';
const DRIFT_CONVERSATION_CHAT_WITH_SUPPORT_MSG: string = 'Chat with support';
const DRIFT_URL_FILTERS = [
  '/broker',
  '/brokers',
  '/employers',
  '/partners',
  '/fsa',
  '/hra',
  '/cobra',
  '/lsa',
  '/mta',
  '/contact-sales',
  '/resources/employer',
  '/resources/broker'
];
const useDrift: DriftHook = () => {
  const [showZendeskChat, setShowZendeskChat] = React.useState(false);
  const apiRef = React.useRef(null);
  const asPath = usePathname();
  const currentPath = asPath?.split('?')[0]?.split('#')[0] || '';

  const shouldRenderDriftScript = DRIFT_URL_FILTERS.includes(currentPath);

  React.useEffect(() => {
    const initDrift = () => {
      if (!shouldRenderDriftScript) {
        apiRef.current?.widget?.hide();
        setShowZendeskChat(true);
        const driftSearchInterval = setInterval(() => {
          if (!window?.drift?.api) return;
          window.drift.api.widget.hide();
          clearInterval(driftSearchInterval);
        }, 3000);
        return;
      }
      if (typeof window !== 'undefined') {
        if (window.drift?.api?.widget) {
          window.drift.api.widget?.show();
        }
        const [headHtmlElement] = document.getElementsByTagName('head');

        const driftSearchInterval = setInterval(() => {
          if (!window?.drift?.api) return;
          window.drift.api.widget.show();
          clearInterval(driftSearchInterval);

          const zendeskScript: HTMLScriptElement = document.createElement('script');
          zendeskScript.type = 'text/javascript';
          zendeskScript.id = 'ze-snippet';
          zendeskScript.async = true;
          zendeskScript.src =
            'https://static.zdassets.com/ekr/snippet.js?key=e33fb74f-e7b6-4d76-81f1-295973cda0f2';

          // @ts-ignore
          drift?.on(DRIFT_STATUS_IS_READY, function (api: DriftApi) {
            apiRef.current = api;
            api.widget.show();

            // @ts-ignore
            drift?.on(DRIFT_CONVERSATION_EVENT, function (data: DriftConversationEventData) {
              const { buttonBody } = data;

              if (buttonBody === DRIFT_CONVERSATION_CHAT_WITH_SUPPORT_MSG) {
                api.widget.hide();
                headHtmlElement.appendChild(zendeskScript);

                setTimeout(() => {
                  setShowZendeskChat(true);
                }, 1000);
              }
            });
          });
        }, 1000);
      }
    };

    initDrift();
  }, [shouldRenderDriftScript]);

  React.useEffect(() => {
    if (typeof window !== 'undefined' && showZendeskChat) {
      const zendeskSearchInterval = setInterval(() => {
        // @ts-ignore
        if (!window?.$zopim) return;
        clearInterval(zendeskSearchInterval);

        // @ts-ignore
        $zopim.livechat.window.show();
      }, 200);
    }
  }, [showZendeskChat]);

  return null;
};

export default useDrift;
